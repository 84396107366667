body {
  margin: 0;
  font-family: 'Red Hat Text';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#f7f7f7;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#site-navbar{
  width: 100%;
}

#site-footer{
  background-color: rgb(40,39,40);
  color: rgb(173,173,173);
  border-top: 5px solid #c5050c;
  font-family: 'Red Hat Text', sans-serif;
  padding-top: 64px;
  margin-top: auto;
  width: 100%;
}

#body-wrapper {
  min-height: calc(100vh - 330px);
  width: 100%;
}

#site-copyright {
  font-family: 'Red Hat Text', sans-serif !important;
}

#site-footer-copyrightlink, #privacy-notice-link{
  text-decoration-color: rgb(173,173,173);
  color: rgb(173,173,173);
}

.site-footer-subheader{
  font-weight: 800;
  font-family: 'Red Hat Display', sans-serif !important;
  margin-top: 0;
  margin-bottom: 8px;
}

.site-footer-link {
  text-decoration: none;
  color: inherit;
}

.site-footer-link:hover{
  color: white;
}

.instruction-text {
  font-family: 'Red Hat Display' !important;
  font-size: 18px !important;
}

.instruction-list span > a {
  color: rgba(0, 0, 0, 0.54);
}

.instruction-list span > a:hover {
  color: rgb(40,39,40);
}

.instruction-list, .instruction-list span {
  text-align: start;
  color: rgba(0, 0, 0, 0.54);
  font-size: 15px !important;
  font-family: 'Red Hat Display' !important;
}

input[type=date]:invalid::-webkit-datetime-edit {
  color: rgb(0,0,0,0.54);
}

label, label span, table th, table td, .form-divider, .MuiDialogContent-root p{
  font-family: 'Red Hat Display' !important;
}

.datatable th {
  color: rgba(0, 0, 0, 0.54);
}

.datatable-filled-row td {
  color: rgba(0, 0, 0, 0.54);
}

.email-list li {
  color: rgba(0, 0, 0, 0.54);
  float: left;
  padding-left: 0px;
}

.service-list li {
  color: rgba(0, 0, 0, 0.54);
  float: left;
  padding-left: 0px;
}

.service-list {
  max-width: 620px;
}

.checkbox {
  color: rgba(0, 0, 0, 0.54);
}

.form-header {
  margin-block-end: 0px;
}

.row-spacer {
  width: 320px;
}

